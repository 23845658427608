import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import Field from '@/components/ui/Field'
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger
} from '@/components/ui/Sheet'
import Switch from '@/components/ui/Switch'

import { IPermission } from '@/types/roles/permission.inteface'
import { ICreateOrUpdateRole, IRole } from '@/types/roles/role.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'
import { permissionGroups } from '@/constants/permission-groups'

interface Props {
  initial?: IRole
}

const CreateOrUpdateRole: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { data } = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const resp = await api<IPermission[]>('/admin/roles/permissions')
      return resp.data
    }
  })

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateRole>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          name: initial.name,
          description: initial.description,
          permissionsIds: initial.permissions.map(permission => permission.id)
        }
      : {
          name: '',
          description: '',
          permissionsIds: []
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            name: initial.name,
            description: initial.description,
            permissionsIds: initial.permissions.map(permission => permission.id)
          }
        : {
            name: '',
            description: '',
            permissionsIds: []
          }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-role' : 'add-role'],
    mutationFn: (data: ICreateOrUpdateRole) =>
      api(initial ? `/admin/roles/${initial.id}` : '/admin/roles', {
        method: initial ? 'PATCH' : 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(`Role ${initial ? 'updated' : 'created'} successfully!`)
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} the role.`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateRole> = async data =>
    mutate(data)

  return (
    <ProtectedComponent
      permissionSlug={initial ? 'roles_update' : 'roles_create'}
    >
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Add Role'}
          </Button>
        </SheetTrigger>
        <SheetContent className='h-screen w-full sm:max-w-lg '>
          <form
            className='flex h-[calc(100svh-100px)] w-full flex-col gap-2 overflow-y-auto p-4 md:gap-4 md:p-6'
            onSubmit={handleSubmit(onSubmit)}
          >
            <SheetTitle>{initial ? 'Edit Role' : 'Add Role'}</SheetTitle>

            <Field
              title='Name'
              placeholder='Name'
              {...register('name', {
                required: 'Name is required'
              })}
              disabled={!!initial}
              error={errors.name?.message}
            />
            <Field
              title='Description'
              placeholder='Description'
              {...register('description', {})}
              error={errors.description?.message}
            />

            <Controller
              name='permissionsIds'
              control={control}
              render={({ field }) => {
                return (
                  <section className='flex flex-col gap-1'>
                    {data ? (
                      <>
                        {permissionGroups.map(group => (
                          <div key={group.name} className='flex flex-col gap-1'>
                            <p className='text-[18px] font-bold text-white'>
                              {group.name}
                            </p>
                            <div className='flex flex-col gap-2'>
                              {group.permissions &&
                                group.permissions.map(permission => {
                                  const findPermission = data.find(
                                    perm => perm.slug === permission
                                  )

                                  if (!findPermission) return null

                                  return (
                                    <Switch
                                      key={findPermission.id}
                                      title={findPermission.description}
                                      checked={field.value.includes(
                                        findPermission.id
                                      )}
                                      onCheckedChange={() => {
                                        if (
                                          field.value.includes(
                                            findPermission.id
                                          )
                                        ) {
                                          field.onChange(
                                            field.value.filter(
                                              perm => perm !== findPermission.id
                                            )
                                          )
                                        } else {
                                          field.onChange([
                                            ...field.value,
                                            findPermission.id
                                          ])
                                        }
                                      }}
                                    />
                                  )
                                })}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No permissions found.</p>
                    )}
                  </section>
                )
              }}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </SheetContent>
      </Sheet>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateRole
