import { useQuery } from '@tanstack/react-query'

import Page from '@/components/Page'
import MetricsCards from '@/components/features/Metrics'

import {
  IAccountMetrics,
  IExchangeMetrics,
  IWalletMetrics
} from '@/types/metrics/metrics.interface'

import {
  formatAccountMetrics,
  formatExchangeMetrics,
  formatFeesMetrics,
  formatWalletMetrics
} from './format'
import api from '@/api'
import { METRICS_TABS } from '@/constants/tabs'

const MetricsPage: React.FC = () => {
  const {
    data: usersData,
    isLoading: usersLoading,
    isError: usersError
  } = useQuery({
    queryKey: ['users-metrics'],
    queryFn: async () => await api.get<IAccountMetrics>('/admin/metrics/users'),
    enabled: true,
    select: res => formatAccountMetrics(res.data)
  })
  const {
    data: exchangeData,
    isLoading: exchangeDataLoading,
    isError: exchangeDataError
  } = useQuery({
    queryKey: ['exchange-metrics'],
    queryFn: async () =>
      await api.get<IExchangeMetrics>('/admin/metrics/exchange'),
    enabled: true,
    select: res => formatExchangeMetrics(res.data)
  })
  const {
    data: flashData,
    isLoading: flashDataLoading,
    isError: flashDataError
  } = useQuery({
    queryKey: ['flash-metrics'],
    queryFn: async () => await api.get<IWalletMetrics>('/admin/metrics/flash'),
    enabled: true,
    select: res => formatWalletMetrics(res.data)
  })
  const {
    data: stashData,
    isLoading: stashDataLoading,
    isError: stashDataError
  } = useQuery({
    queryKey: ['stash-metrics'],
    queryFn: async () => await api.get<IWalletMetrics>('/admin/metrics/stash'),
    enabled: true,
    select: res => formatWalletMetrics(res.data)
  })
  const {
    data: starterData,
    isLoading: starterDataLoading,
    isError: starterDataError
  } = useQuery({
    queryKey: ['starter-metrics'],
    queryFn: async () =>
      await api.get<IWalletMetrics>('/admin/metrics/starter'),
    enabled: true,
    select: res => formatWalletMetrics(res.data)
  })
  const {
    data: feesData,
    isLoading: feesDataLoading,
    isError: feesDataError
  } = useQuery({
    queryKey: ['fees-metrics'],
    queryFn: async () => await api.get('/admin/metrics/fees'),
    enabled: true,
    select: res => formatFeesMetrics(res.data)
  })

  return (
    <Page permissionSlug='metrics_view' pageTitle='Metrics' tabs={METRICS_TABS}>
      <MetricsCards
        cardsConfig={{ title: 'Accounts', count: 4 }}
        isLoading={usersLoading}
        isError={usersError}
        data={usersData}
      />
      <MetricsCards
        cardsConfig={{ title: 'Exchange', count: 4, subtitle: 'ZeroHash' }}
        isLoading={exchangeDataLoading}
        isError={exchangeDataError}
        data={exchangeData}
      />
      <MetricsCards
        cardsConfig={{ title: 'Flash', count: 6, subtitle: 'Breez' }}
        isLoading={flashDataLoading}
        isError={flashDataError}
        data={flashData}
      />
      <MetricsCards
        cardsConfig={{ title: 'Stash', count: 6, subtitle: 'BDK' }}
        isLoading={stashDataLoading}
        isError={stashDataError}
        data={stashData}
      />
      <MetricsCards
        cardsConfig={{ title: 'Starter', count: 6, subtitle: 'IBEX' }}
        isLoading={starterDataLoading}
        isError={starterDataError}
        data={starterData}
      />
      <MetricsCards
        cardsConfig={{
          title: 'Fees Generated',
          count: 3,
          subtitle: 'From our side'
        }}
        isLoading={feesDataLoading}
        isError={feesDataError}
        data={feesData}
      />
    </Page>
  )
}

export default MetricsPage
