import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IOnchainTransaction } from '@/types/transaction-onchain/transaction-onchain.interface'

import formatId from '@/utils/formatId'

import { msatsToSats } from '@/utils'

export const onchainColumns: ColumnDef<IOnchainTransaction>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      return <p>{row.original.status}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return <p>{row.original.type}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'sender',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Sender' />
    },
    cell: ({ row }) => {
      if (!row.original.sender)
        return <p className='text-foreground/50'>Unknown</p>
      return <UserDetailsModal fromOtherPage user={row.original.sender} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'receiver',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Receiver' />
    },
    cell: ({ row }) => {
      if (!row.original.receiver)
        return <p className='text-foreground/50'>Unknown</p>
      return <UserDetailsModal fromOtherPage user={row.original.receiver} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'amount_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return `${msatsToSats(String(row.original.amount_msats))} sats`
    },
    enableSorting: false
  },
  {
    accessorKey: 'fee_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Fee' />
    },
    cell: ({ row }) => {
      return `${msatsToSats(String(row.original.fee_msats))} sats`
    },
    enableSorting: false
  },
  {
    accessorKey: 'destination_address',
    header: ({ column }) => {
      return (
        <DataTableColumnHeader column={column} name='Destination Address' />
      )
    },
    cell: ({ row }) => {
      return <p>{row.original.destination_address}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'transaction_hash',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Hash' />
    },
    cell: ({ row }) => {
      if (!row.original.transaction_hash) {
        return <p className='text-foreground/50'>Unknown</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.transaction_hash}`)
            toast.success('Hash Copied to clipboard')
          }}
        >
          {formatId(row.original.transaction_hash)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    },
    enableSorting: false
  }
]
