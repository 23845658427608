import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field, { ErrorMessage } from '@/components/ui/Field'
import Switch from '@/components/ui/Switch'

import {
  ICreateOrUpdateIpAddress,
  IIpAddress
} from '@/types/ip-address/ip-address.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: IIpAddress
}

const CreateOrUpdateIp: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm<ICreateOrUpdateIpAddress>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          value: initial.value,
          is_blocked: initial.is_blocked,
          account: initial.account
        }
      : {
          value: '',
          is_blocked: false,
          account: []
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            value: initial.value,
            is_blocked: initial.is_blocked,
            account: initial.account
          }
        : {
            value: '',
            is_blocked: false,
            account: []
          }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-ip' : 'add-ip'],
    mutationFn: (data: ICreateOrUpdateIpAddress) =>
      api(initial ? `/admin/ips/${initial.id}` : '/admin/ips', {
        method: initial ? 'PATCH' : 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(`IP ${initial ? 'updated' : 'created'} successfully!`)
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} IP`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateIpAddress> = async data =>
    mutate(data)

  return (
    <ProtectedComponent permissionSlug={'ip_addresses_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Add IP'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{initial ? 'Edit IP' : 'Add IP'}</DialogTitle>
            <DialogDescription>
              {initial ? 'Edit the IP details' : 'Add a new IP'}
            </DialogDescription>
          </DialogHeader>
          <form
            className='flex w-full flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              title='Value'
              placeholder='Value'
              className='!mt-0'
              {...register('value', {
                required: 'Value is required',
                maxLength: {
                  value: 255,
                  message: 'Value should not exceed 255 characters'
                }
              })}
              error={errors.value?.message}
            />

            <Controller
              name='is_blocked'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Is Blocked'
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />

            {/* <Controller
              name='accountId'
              control={control}
              render={({ field }) => (
                <label className='flex flex-col gap-2'>
                  <p className='font-semibold'>Account</p>
                  <AccountsSelect
                    preventDisable
                    initialAccount={
                      initial?.account ? initial.account : undefined
                    }
                    accountId={watch('accountId')}
                    setAccountId={(accountId: string | undefined) => {
                      if (accountId) {
                        field.onChange(accountId)
                      } else {
                        field.onChange(undefined)
                      }
                    }}
                  />
                  <ErrorMessage error={errors?.accountId?.message} />
                </label>
              )}
            /> */}

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateIp
